import ClapprPlayer from './ClapprPlayer';
import SldpPlayer from './SldpPlayer';

export default function Root({ stream, splash, mode }) {
	const server = 'cdn.b3video.lt';
	//
	return (
		<div class="flex-container">
			<div class={mode == 'sldp' ? 'row' : 'row fullheight'}>
				{mode == 'sldp' ? (
					<SldpPlayer stream={stream} server={server} splash={splash} />
				) : mode == 'hls' ? (
					<ClapprPlayer
						stream={stream}
						server={server}
						splash={splash}
						lowLatencyMode={false}
					/>
				) : (
					<ClapprPlayer
						stream={stream}
						server={server}
						splash={splash}
						lowLatencyMode={true}
					/>
				)}
			</div>
		</div>
	);
}
