import { isIOS } from './DeviceInfo';

export function parseSplashScreen(splash) {
	let splash_screen;
	if (splash && !isIOS) {
		try {
			const decoded = atob(splash);
			const url = new URL(decoded);
			splash_screen = url.toString();
		} catch (e) {
			console.error(e);
		}
	}
	return splash_screen;
}
