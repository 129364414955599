const iOS_1to12 = /iPad|iPhone|iPod/.test(navigator.platform);

const iOS13_iPad =
	navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1;

const iOS1to12quirk = function () {
	const audio = new Audio(); // temporary Audio object
	audio.volume = 0.5; // has no effect on iOS <= 12
	return audio.volume === 1;
};

export const isIOS =
	!window.MSStream && (iOS_1to12 || iOS13_iPad || iOS1to12quirk());
