import { h } from 'preact';
import { useRef, useEffect } from 'preact/hooks';
import { parseSplashScreen } from '../utils/Misc';

function loadScript(src) {
	return new Promise((resolve, reject) => {
		let resolved = false,
			errored = false,
			body = document.getElementsByTagName('body')[0],
			tag = document.createElement('script');

		tag.type = 'text/javascript';
		tag.async = false; // Load in order

		// eslint-disable-next-line no-unused-vars
		const handleCallback = (tag.onreadystatechange = function () {
			if (resolved) return handleLoad();
			if (errored) return handleReject();
			const state = tag.readyState;
			if (state === 'complete') {
				handleLoad();
			} else if (state === 'error') {
				handleReject();
			}
		});

		const handleLoad = () => {
			resolved = true;
			resolve(src);
		};
		const handleReject = () => {
			errored = true;
			reject(src);
		};

		tag.addEventListener('load', handleLoad);
		tag.addEventListener('error', handleReject);
		tag.src = src;
		body.appendChild(tag);
		return tag;
	});
}

let loadedScript = null;

export default function SldpPlayer({ stream, server, splash }) {
	const player = useRef();
	const splash_screen = parseSplashScreen(splash);
	//
	useEffect(() => {
		if (player.current) {
			player.current.destroy();
		}
		//
		if (!server || !stream) {
			return;
		}
		//
		async function load() {
			if (loadedScript === null) {
				loadedScript = await loadScript('/assets/sldp-v2.21.1.min.js');
			}
			// eslint-disable-next-line no-undef
			player.current = SLDP.init({
				container: 'player',
				stream_url: `wss://${server}:1043/edge-abr/${stream}`,
				autoplay: !splash_screen,
				muted: !splash_screen,
				splash_screen,
				reconnects: 9999,
				// height: 'parent',
				width: 'parent',
			});
		}
		//
		load().catch(console.log);
	}, [server, stream, splash_screen]);
	//
	return <div id="player" />;
}
