import { Router } from 'preact-router';
import Root from './components/Root';
import './style';

export default function App() {
	return (
		<Router>
			<Root path="/:stream/:splash?" />
			<Root path="/sldp/:stream/:splash?" mode="sldp" />
			<Root path="/hls/:stream/:splash?" mode="hls" />
			<Root default stream="default" />
		</Router>
	);
}
