import { h } from 'preact';
import { useRef, useEffect, useState } from 'preact/hooks';
import { parseSplashScreen } from '../utils/Misc';
import Clappr from '@clappr/core';
import HlsjsPlayback from '@clappr/hlsjs-playback';
import { MediaControl, Poster, ErrorScreen } from '@clappr/plugins';

const RELOAD_ON_ERROR_TIMEOUT = 3000;

export default function ClapprPlayer({
	stream,
	server,
	splash,
	lowLatencyMode,
}) {
	const player = useRef();
	const [reload, setReload] = useState(0);
	const [error, setError] = useState(0);
	//
	useEffect(() => {
		if (player.current) {
			player.current.destroy();
			player.current = null;
		}
		//
		if (!server || !stream) {
			return;
		}
		//
		const splash_screen = parseSplashScreen(splash);
		const autoPlay = !splash_screen;
		const mute = !splash_screen;
		//
		player.current = new Clappr.Player({
			parentId: '#player',
			width: 'parent',
			height: '100%',
			source: `https://${server}:1043/edge-abr/${stream}/playlist.m3u8`,
			mute,
			autoPlay: false,
			poster: splash_screen,
			baseUrl: window.location.href,
			playback: {
				playInline: true,
				hlsjsConfig: {
					capLevelToPlayerSize: true,
					lowLatencyMode,
				},
			},
			// hlsRecoverAttempts: 9999,
			hlsUseNextLevel: true,
			plugins: [HlsjsPlayback, MediaControl, Poster, ErrorScreen],
			levelSelectorConfig: {
				labelCallback(playbackLevel) {
					return `${playbackLevel.level.height}p`;
				},
			},
			events: {
				onError: () => setError(error + 1),
			},
		});
		//
		if (autoPlay) {
			// manual autoplay for android/ios
			const p = player.current;
			if (!player.current.isReady) {
				player.current.on('ready', () => p.play());
			} else {
				p.play();
			}
		}
	}, [server, stream, splash, reload, lowLatencyMode]);
	//
	useEffect(() => {
		if (reload < error) {
			const id = setTimeout(
				() => setReload(reload + 1),
				RELOAD_ON_ERROR_TIMEOUT,
			);
			return () => clearTimeout(id);
		}
	}, [error, reload]);
	//
	return <div id="player" style="height: 100%" />;
}
